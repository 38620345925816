::-webkit-scrollbar {
  display: none;
}

p {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.25rem;
  font-size: 16px;
}

p2 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.25rem;
  font-size: 14px;
}

li {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.25rem;
  font-size: 16px;
  padding: 5px;
}

.starter {

  font-family: 'Montserrat', sans-serif;
  line-height: 2.5rem;
  font-size: 34px!important;
  padding: 5px; 
  width: 70;

}

.starter-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:end;
  height: 280px;
  width: 100%;
}

.starter-txt-container{

  padding: 0!important;
  height: 80vh;
  justify-content: center;

}

.starter-txt-box {
  
  width: 70%!important;
  border-radius: 30px!important;

}